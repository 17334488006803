// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-external-features-js": () => import("/opt/build/repo/src/external/features.js" /* webpackChunkName: "component---src-external-features-js" */),
  "component---src-external-pricing-js": () => import("/opt/build/repo/src/external/pricing.js" /* webpackChunkName: "component---src-external-pricing-js" */),
  "component---src-external-security-js": () => import("/opt/build/repo/src/external/security.js" /* webpackChunkName: "component---src-external-security-js" */),
  "component---src-external-terms-js": () => import("/opt/build/repo/src/external/terms.js" /* webpackChunkName: "component---src-external-terms-js" */),
  "component---src-external-privacy-js": () => import("/opt/build/repo/src/external/privacy.js" /* webpackChunkName: "component---src-external-privacy-js" */),
  "component---src-external-cookies-js": () => import("/opt/build/repo/src/external/cookies.js" /* webpackChunkName: "component---src-external-cookies-js" */),
  "component---src-external-gdpr-js": () => import("/opt/build/repo/src/external/gdpr.js" /* webpackChunkName: "component---src-external-gdpr-js" */),
  "component---src-external-register-js": () => import("/opt/build/repo/src/external/register.js" /* webpackChunkName: "component---src-external-register-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

